import { Show, createSignal } from "solid-js";
import { action, redirect, useSubmission } from "@solidjs/router";

import styles from "./(home).module.css";
import Button from "~/components/Button";
import { createClient } from "~/supabase";

const signIn = action(async (form: FormData) => {
  const email = form.get("email") as string;
  const password = form.get("password") as string;
  const supabase = createClient();
  const { error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    console.log("SignIn Error", error.message);
    throw error;
  } else {
    console.log("SignIn Success");
    throw redirect("/app/menu");
  }
});

export default function Home() {
  const signingIn = useSubmission(signIn);
  const [showLoginForm, setShowLoginForm] = createSignal(false);

  return (
    <>
      <main class={styles.container}>
        <div class={styles.content}>
          <img src="/images/logo.webp" alt="BarWise" />
          <Show when={!showLoginForm()}>
            <Button onClick={() => setShowLoginForm(true)}>Log In</Button>
          </Show>
          <Show when={showLoginForm()}>
            <Show when={signingIn.error}>
              <div
                classList={{ [styles.alert]: true, [styles.alertError]: true }}
              >
                <p>Something went wrong: {signingIn.error.message}</p>
              </div>
            </Show>
            <div>
              <form class={styles.form} action={signIn} method="post">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  autocomplete="email"
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  autocomplete="current-password"
                />
                <Button disabled={signingIn.pending} type="submit">
                  Sign in
                </Button>
              </form>
            </div>
          </Show>
        </div>
      </main>
    </>
  );
}
